<template>
  <v-app>
    <v-row class="justify-center align-content-center align-center h-login">
      <v-col style="max-width:360px; flex-basis:unset;">
        <v-img
          max-width="300"
          max-height="39"
          alt="Hanwha Systems/ICT"
          class="mx-auto mb-16"
        ></v-img>
        <p class="color_blue_1--text text-center">해당페이지는 모바일을 지원하지 않습니다. </p>
        <p class="color_blue_1--text text-center">모바일은 향후 지원 예정입니다.</p>
      </v-col>
    </v-row>
    <v-card
      flat
      class="justify-center align-content-bottom align-center h-login-footer pt-10">
      <p class="f12 text-center">Copyrights 2022 © <strong>Hanwha Systems/ICT</strong>. All Rights Reserved.</p>
    </v-card>

    <div class="h-l-bg-1"></div>
    <div class="h-l-bg-2"></div>
    <div class="h-l-bg-3"></div>
    <div class="h-l-bg-4"></div>
    <div class="h-l-bg-5"></div>
    <div class="h-l-bg-6"></div>
  </v-app>
  <!-- <div>
    <strong>페이지를 찾을 수 없습니다.</strong>
    <span>입력한 주소가 맞는지 확인해 주세요.</span>
    <router-link to="/" tag="button">메인으로 이동</router-link>
  </div> -->
</template>

<script>
export default {
  name: 'NotSupportIE',
};
</script>
